import React, { memo } from 'react';
import IDataExtension from '../models/data-extension';
import { Link } from 'react-router-dom';
import { useAppContext } from '../context';

interface IProps {
  data?: IDataExtension
  parentPath: string
}

const DataExtensionCard: React.FC<IProps> = ({ data, parentPath }) => {
  const { formatTime } = useAppContext();

  return (
    <Link
      className='bg-white border border-gray-200 rounded-md shadow p-3 hover:shadow-lg transition-shadow duration-300'
      to={`${parentPath}/${data?.id || 'new'}`}
    >
      {data ? (
        <>
          <h1 className='font-bold text-lg capitalize'>{data?.name}</h1>
          <div className='text-sm text-gray-600 mb-3'>{data?.description}</div>
          <div className='text-md font-semibold'>Total User: {(data?.dataSet || []).filter(item => item.phoneNumber).length}</div>
          <div className='flex flex-row justify-between mt-4'>
            <div className='text-[10px] text-gray-600'>Creator: {data?.createdBy}</div>
            <div className='text-[10px] text-gray-600 italic'>Updated: {formatTime(data?.latestUpdated || data?.createdAt )}</div>
          </div>
        </>
      ) : (<div className='flex flex-col items-center'>
        <div className='mb-4'>Create new</div>
        <svg className="w-[60px] h-[60px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
          <path d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-2V5a1 1 0 0 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V9h2a1 1 0 1 0 0-2Z" />
        </svg>
      </div>)}

    </Link>
  )
};

export default memo(DataExtensionCard);
