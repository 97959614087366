/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import csvToJson from 'csvtojson';
import _uniqBy from 'lodash/uniqBy';
import _pick from 'lodash/pick';
import _chunk from 'lodash/chunk';
import _uniq from 'lodash/uniq';
import _flatten from 'lodash/flatten';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CoreInput from '../../components/core/core-input';
import LabelUI from '../../components/core/label';
import { useAppContext } from '../../context';
import { IDataSetRow } from '../../models/data-extension';
import { EMessageStatus } from '../../models/message';
import { useOnClickOutside } from '../../utils/component-utils';
import MessageStatus from '../../components/message-status';
import { apiInstance } from '../../utils/api-service';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ISendAction, { ESendAction } from '../../models/send-action';
import TheTable from '../../components/the-table';
import { formatMessage } from '../../utils/helper';

interface IFormData {
  name: string
  description: string
  dataSet: ISendAction[]
  ignoreList: string[]
  content: string
  status: EMessageStatus
  selectedDeId: string[]
  scheduleTime?: number
  senderPhone: string
  shortenDomain: string
}

const MessageLength = 160;
const ApiBatchSize = 5; // 20 per 1 loop
const ApiBatchDelay = 500; // 0.5 second

const AllowChangeTime = 10 * 60 * 1000; // 10 mins

const TextMessageDetailsPage: React.FC = () => {
  const [selectedUsersFromFile, setSelectedUsersFromFile] = useState<IDataSetRow[]>([]);
  const [scheduleSend, setSchedule] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileList>();
  const [displayTable, setDisplayTable] = useState<ISendAction[]>([]);
  const [onlyShowOptOutList, setOnlyShowOptOut] = useState(false);
  const [showDomainDropdown, setShowDomainDropdown] = useState(false);
  const {
    dataExtensions,
    pageLoading,
    onStartLoading,
    onEndLoading,
    userToken,
    senderPhones,
    optoutList,
    currentPhoneNumber,
    shortenDomains
  } = useAppContext();
  const params = useParams();
  const navigate = useNavigate();
  const today = useMemo(() => new Date(), []);

  const dropdownMenuWrapper: RefObject<HTMLDivElement> = useRef<
    HTMLDivElement
  >() as RefObject<HTMLDivElement>;
  const dropdownDomainMenuWrapper: RefObject<HTMLDivElement> = useRef<
    HTMLDivElement
  >() as RefObject<HTMLDivElement>;

  useOnClickOutside(dropdownMenuWrapper, () => setShowDropdown(false));
  useOnClickOutside(dropdownDomainMenuWrapper, () => setShowDomainDropdown(false));

  const onSubmitForm = useCallback((formValues: IFormData) => {
    onStartLoading();
    apiInstance(userToken)?.post('/campaign', { ...formValues, status: EMessageStatus.Draft, senderPhone: currentPhoneNumber }).then((data) => {
      onEndLoading();
      toast('Save succesfully!', { type: 'success', autoClose: 500 });

      if (params.projectId === 'new') {
        navigate(`/text-message/${data.data.id}`);
      }
    });
  }, [onStartLoading, onEndLoading, userToken, currentPhoneNumber]);

  const formData = useFormik<IFormData>({
    // @ts-ignore
    initialValues: {
      name: '',
      description: '',
      content: '',
      dataSet: [],
      ignoreList: [],
      status: EMessageStatus.New,
      selectedDeId: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please fill the campaign name'),
      description: Yup.string().required('Please fill the campaign description'),
      content: Yup.string().required('Please fill the body'),
      dataSet: Yup.array().min(1, 'Please select the users')
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onSubmitForm
  });

  const isReadyCampaign = useMemo(() => [
    EMessageStatus.Ready,
    EMessageStatus.Processing,
    EMessageStatus.Sent,
    EMessageStatus.Scheduled
  ].includes(formData.values.status), [formData.values.status]);

  const hours = useMemo(() => {
    const startDate = (formData.values.scheduleTime || -1) > 1 ? new Date(formData.values.scheduleTime || 0) : today;
    let startList: number[] = []
    if (startDate <= today) {
      // @ts-ignore
      startList = [...Array((today.getHours() + 1)).keys()];
    }

    // @ts-ignore
    const midList: number[] = [...Array(20 - 10).keys()].map(item => item + 10);

    return [...startList, ...midList];
  }, [formData.values.scheduleTime, today]);

  const excludeTime = useMemo(() => _flatten(hours.map(item => {
    const theDate = new Date(today);
    theDate.setHours(item);
    theDate.setMinutes(0);
    theDate.setSeconds(0);
    theDate.setMilliseconds(0);

    const anotherOne = new Date(theDate);
    anotherOne.setMinutes(30);

    return [theDate, anotherOne];
  })), [today, hours]);

  const phoneList = useMemo(() => {
    return formData.values.dataSet.map(data => data.phoneNumber);
  }, [formData.values.dataSet]);

  const tableSet = useMemo(() =>
    (displayTable.length > 0 ? displayTable : formData?.values?.dataSet).filter(item => item.phoneNumber),
    [displayTable, formData?.values?.dataSet]
  );

  const currentUsageSendPhone = useMemo(
    () => {
      if (formData.values?.status === EMessageStatus.Sent) {
        return formData.values.senderPhone || senderPhones[0]; // Default for old numbers
      }

      return formData.values.senderPhone || currentPhoneNumber || senderPhones[0];
    },
    [formData.values.senderPhone, senderPhones, currentPhoneNumber, formData.values.status]);
  
  const optOutCandidates = useMemo(() => (
    tableSet.filter(item =>
      optoutList.findIndex(list =>
        list.phoneNumber?.replace('+', '') === item.phoneNumber?.replace('+', '')
        && currentUsageSendPhone.replace('+', '') === list.senderNumber?.replace('+', '')
      ) !== -1)
  ), [tableSet, optoutList, currentUsageSendPhone]);

  const totalOptOutUser = useMemo(() =>
    optOutCandidates.length, [optOutCandidates]);

  const totalOptoutselected = useMemo(() =>
    formData.values.ignoreList.filter(phone => optoutList.findIndex(item => item.phoneNumber.replace('+', '') === phone.replace('+', '')) !== -1).length
    , [formData.values.ignoreList, optoutList]);

  const totalErrorMessages = useMemo(() => (
    tableSet.filter(item => item.status === ESendAction.Error).length
  ), [tableSet]);

  const totalSentMessages = useMemo(() => (
    tableSet.filter(item => item.status === ESendAction.Sent).length
  ), [tableSet]);

  const percentageCalculate = useCallback((progress: number, total: number) => (
    `${((progress / total) * 100).toFixed(2)}%`
  ), []);

  const preparedMessages = useMemo(() => {
    return tableSet.filter(item => item.status === ESendAction.Ready).length || 0;
  }, [tableSet]);

  const numberOfMessage = useMemo(() => {
    if (formData.values.content.length) {
      return Math.ceil(formatMessage(formData.values.content, formData.values?.shortenDomain).length / MessageLength);
    }

    return 0;
  }, [formData.values.content?.length, formData.values?.shortenDomain])

  const selectedUsersFromDE: IDataSetRow[] = useMemo(() => {
    const selectedDEs = dataExtensions.filter(de => (formData?.values?.selectedDeId || []).includes(de.id));

    return selectedDEs.map(item => item.dataSet).flat(1);
  }, [formData?.values?.selectedDeId, dataExtensions]);

  const totalSendCandidates = useMemo(
    () => tableSet.length - formData?.values?.ignoreList?.length,
    [tableSet, formData.values.ignoreList]
  );

  const preparedContent = useMemo(() => {
    if (isReadyCampaign) {
      return '100%';
    }

    if (formData.values?.dataSet?.length) {
      return `${(preparedMessages / (formData?.values?.dataSet.length - formData?.values?.ignoreList?.length) * 100).toFixed(2)}%`;
    }

    return '0%';
  }, [preparedMessages, formData.values.dataSet, formData.values.ignoreList, isReadyCampaign]);

  const onClickSelectDE = useCallback((id: string) => {
    const selectedIds = formData?.values?.selectedDeId || [];
    formData.setFieldValue('ignoreList', []);
    if (selectedIds.includes(id)) {
      formData.setFieldValue('selectedDeId', selectedIds.filter(idx => idx !== id));
    } else {
      formData.setFieldValue('selectedDeId', [...selectedIds, id]);
    }
  }, [formData?.values]);

  const onSelectDomain = useCallback((domain: string) => {
    formData.setFieldValue('shortenDomain', domain);
  }, []);

  const onClickIgnore = useCallback((id: string) => {
    const ignoreList = formData?.values?.ignoreList || [];
    if (ignoreList.includes(id)) {
      formData.setFieldValue('ignoreList', ignoreList.filter(idx => idx !== id));
    } else {
      formData.setFieldValue('ignoreList', [...ignoreList, id]);
    }
  }, [formData.values.ignoreList]);

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files) {
      setSelectedFiles(event.target?.files);
    }
  }, [formData]);

  const readFileToString = useCallback((file: File) => {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target?.result?.toString());
      }

      reader.readAsText(file);
    });
  }, []);

  const readCsvFile = useCallback((file: File) => {
    return new Promise(resolve => {
      readFileToString(file).then(text => {
        csvToJson({ delimiter: ';' }).fromString(text as string).then(rows => {
          resolve(rows);
        })
      }).catch(() => resolve([]));
    });
  }, []);

  const updateATableRow = useCallback((row: ISendAction) => {
    setDisplayTable(current => current.map(item => {
      if (item.phoneNumber === row.phoneNumber) {
        return row;
      }

      return item;
    }))
  }, [setDisplayTable]);

  const processARow = useCallback((row: ISendAction) => {
    if ((formData.values.ignoreList || []).includes(row.phoneNumber || '')) {
      updateATableRow({
        ...row,
        status: ESendAction.NotSend
      });

      return row;
    }

    updateATableRow({
      ...row,
      status: ESendAction.Creating
    });

    return new Promise(resolve => {
      apiInstance(userToken)?.post('/send-action', {
        campaignId: params.projectId,
        name: row.name,
        phoneNumber: row.phoneNumber,
        senderPhone: formData.values.senderPhone || senderPhones[0],
        content: formData.values.content,
        status: ESendAction.Ready,
        shortenDomain: formData.values.shortenDomain
      }).then(res => {
        updateATableRow(res.data);
        resolve(res.data);
      });
    })
  }, [userToken, formData.values.senderPhone, formData.values.ignoreList, updateATableRow, phoneList]);

  const disableEdit = useMemo(() => ![EMessageStatus.Draft, EMessageStatus.New].includes(formData.values.status) || pageLoading, [formData.values.status, pageLoading]);

  const processCreateASetSendAction = useCallback((theSet: ISendAction[], userToken: string) => {
    return new Promise(resolve => {
      Promise.allSettled(theSet.map(set => {
        return processARow(set);
      })).then((allResults) => {
        // @ts-ignore
        const finalResutls = allResults.map(result => _pick(result.value, ['id', 'phoneNumber', 'name', 'trackingIds', 'status']));
        setTimeout(() => {
          resolve(finalResutls);
        }, ApiBatchDelay);
      });
    })
  }, [processARow]);

  const onReadyToSend = useCallback(async () => {
    onStartLoading();
    setDisplayTable(formData.values.dataSet);
    const allSet = formData.values?.dataSet || [];
    const packs = _chunk(allSet, ApiBatchSize);
    let allResults: any[] = [];

    for (let index = 0; index < packs.length; index++) {
      const aSet = packs[index];
      const setResult = await processCreateASetSendAction(aSet, userToken);
      allResults = allResults.concat(setResult);
    }

    apiInstance(userToken)?.post('/campaign', {
      id: params.projectId,
      status: EMessageStatus.Ready,
      dataSet: allResults
    }).then((res) => {
      formData.setValues(res.data);
      onEndLoading();
    }).catch(() => {
      onEndLoading();
    });
  }, [phoneList, userToken, params]);

  const onProcessASet = useCallback((pack: ISendAction[], userToken: string) => {
    return new Promise(resolve => {
      Promise.allSettled(pack.map(set => {
        return new Promise(resolve => {
          if (set.id && set.status !== ESendAction.Sent) {
            updateATableRow({
              ...set,
              status: ESendAction.Processing,
            });

            apiInstance(userToken)?.put(`/send-action/${set.id}`, set).then((res) => {
              const isGood = res.data?.status;
              const response = {
                ...set,
                status: isGood ? ESendAction.Sent : ESendAction.Error
              };

              updateATableRow(response);

              setTimeout(() => {
                resolve(response);
              }, ApiBatchDelay);
            });
          } else {
            resolve(set);
          }
        });
      })).then((allResults) => {
        // @ts-ignore
        const finalResutls = allResults.map(result => _pick(result.value, ['id', 'phoneNumber', 'name', 'trackingIds', 'status']));
        resolve(finalResutls);
      });
    });
  }, [updateATableRow, userToken]);

  const onSendNow = useCallback((formData: FormikProps<IFormData>) => {
    onStartLoading();

    apiInstance(userToken)?.post('/campaign', {
      id: params.projectId,
      status: EMessageStatus.Processing
    }).then(async () => {
      formData.setFieldValue('status', EMessageStatus.Processing);
      setDisplayTable(formData.values.dataSet);
      const allSet = _uniqBy(formData.values?.dataSet || [], 'phoneNumber');
      const packs = _chunk(allSet, 1);
      let finalResolved: any[] = [];
  
      for (let index = 0; index < packs.length; index++) {
        const theSet = packs[index];
        const setResults = await onProcessASet(theSet, userToken);
        finalResolved = finalResolved.concat(setResults);
      }
  
      apiInstance(userToken)?.post('/campaign', {
        id: params.projectId,
        status: EMessageStatus.Sent,
        dataSet: finalResolved
      }).then((res) => {
        formData.setValues(res.data);
        onEndLoading();
      }).catch(() => {
        onEndLoading();
      });
    }).catch(() => {
      onEndLoading();
      toast('Cannot Send - Please try again!', { autoClose: 300, type: 'error' });
    });
  }, [phoneList, userToken, onProcessASet]);

  const onScheduleMessage = useCallback(async (formData: FormikProps<IFormData>) => {
    onStartLoading();
    apiInstance(userToken)?.post('/campaign', {
      id: params.projectId,
      status: EMessageStatus.Scheduled,
      scheduleTime: formData.values.scheduleTime
    }).then((res) => {
      formData.setValues(res.data);
      onEndLoading();
    }).catch(() => {
      onEndLoading();
    });
  }, [params]);

  const onUpdateTheScheduleTime = useCallback(async (formData: FormikProps<IFormData>, newTime: number, isRemove = false) => {
    onStartLoading();
    apiInstance(userToken)?.post('/campaign', {
      id: params.projectId,
      ...isRemove ? {
        status: EMessageStatus.Ready,
        scheduleTime: -1
      } : {
        scheduleTime: newTime
      },
    }).then((res) => {
      formData.setValues(res.data);
      toast('The campaign is updated succesfully!', { autoClose: 300, type: 'success' });
      onEndLoading();
    }).catch(() => {
      toast('Something went wrong!', { autoClose: 300, type: 'error' });
      onEndLoading();
    });
  }, [params]);

  const handleSyncData = useCallback((formData: FormikProps<IFormData>) => {
    onStartLoading();
    apiInstance(userToken)?.get('/send-action', { params: { campaignId: params.projectId} }).then(res => {
      const syncData: ISendAction[] = res.data;
      const newSet: ISendAction[] = formData.values.dataSet.map(sendAction => {
        if (sendAction.status !== ESendAction.Sent) {
          const newRecord = syncData.find(action => action.status === ESendAction.Sent && sendAction.id === action.id);

          if (newRecord) {
            return {
              ...sendAction,
              status: newRecord.status,
              trackingIds: newRecord.trackingIds
            }
          }

          return sendAction;
        }
        
        return sendAction;
      });

      apiInstance(userToken)?.post('/campaign', {
        id: params.projectId,
        dataSet: newSet,
        status: EMessageStatus.Sent
      }).then((res) => {
        formData.setValues(res.data);
        onEndLoading();
      });
    });
  }, [userToken, params]);

  useEffect(() => {
    if (shortenDomains?.length > 0) {
      formData.setFieldValue('shortenDomain', (shortenDomains[0]));
    }
  }, [shortenDomains]);

  useEffect(() => {
    if ((selectedFiles?.length || 0) > 0) {
      Promise.all(Array.from(selectedFiles || []).map(item => readCsvFile(item))).then((resolves) => {
        // @ts-ignore
        setSelectedUsersFromFile(resolves.flat(1).filter(item => item.phoneNumber));
      })
    }
  }, [selectedFiles, setSelectedUsersFromFile, readCsvFile]);

  useEffect(() => {
    if (params.projectId && userToken && params.projectId !== 'new') {
      onStartLoading();
      apiInstance(userToken)?.get(`/campaign/${params.projectId}`).then(res => {
        onEndLoading();
        formData.setValues(res.data);
      });
    }
  }, [userToken, params.projectId]);

  useEffect(() => {
    const currentSelectedData = _uniqBy([...selectedUsersFromFile, ...selectedUsersFromDE], 'phoneNumber');

    if (currentSelectedData.length > 0 && currentSelectedData.length !== formData.values.dataSet.length) {
      formData.setFieldValue('dataSet', currentSelectedData);
    } else {
      if (selectedUsersFromFile.length === 0 && selectedUsersFromDE.length === 0) {
        formData.setFieldValue('dataSet', []);
      }
    }
  }, [selectedUsersFromDE, selectedUsersFromFile, formData.values.dataSet.length]);

  useEffect(() => {
    if (isReadyCampaign === false && optOutCandidates.length > 0) {
      const ignoreList = formData?.values?.ignoreList || [];
      formData.setFieldValue('ignoreList', _uniq([...ignoreList, ...optOutCandidates.map(item => item.phoneNumber)]));
    } 
  }, [optOutCandidates, isReadyCampaign]);

  return (
    <div className='flex flex-col'>
      <form onSubmit={formData.handleSubmit}>
        <div className={`flex space-x-5 ${disableEdit ? 'pointer-events-none opacity-25' : ''}`}>
          <div className='flex-1'>
            <div className='flex space-x-5'>
              <CoreInput formData={formData} fieldKey='name' fieldName='Campaign Name' />
              <CoreInput formData={formData} fieldKey='description' fieldName='Description' />
            </div>
            <div className='flex space-x-5'>
              <div className='flex-1'>
                <LabelUI isError={false}>
                  Upload CSV files
                </LabelUI>
                <input
                  multiple
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  accept='.csv'
                  name='csvFile' type="file" onChange={handleFileChange} />
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">CSV File only.</p>
              </div>
              <div className='flex flex-1 flex-row space-x-2'>
                <div className='flex-1'>
                  <LabelUI isError={false}>
                    Data Extension
                  </LabelUI>
                  <button
                    disabled={dataExtensions.length === 0}
                    onClick={() => setShowDropdown(!showDropdown)}
                    className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="button">
                    {formData?.values?.selectedDeId?.length || 0} selected <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg></button>

                  <div ref={dropdownMenuWrapper} className={`z-10 w-48 bg-white rounded-lg shadow ${showDropdown ? 'absolute' : 'hidden'}`}>
                    <ul className="p-3 space-y-1 text-sm text-gray-700 max-h-[300px] overflow-y-scroll">
                      {
                        dataExtensions.map(dataExtension => (
                          <li key={dataExtension.id}>
                            <div onClick={() => onClickSelectDE(dataExtension.id)} className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                              <input
                                type="checkbox"
                                checked={(formData?.values?.selectedDeId || []).includes(dataExtension.id)}
                                value=""
                                onChange={() => { }}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              <label
                                className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                              >
                                {dataExtension.name}
                              </label>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>

                <div className='flex-1'>
                  <LabelUI isError={false}>
                    Shorten Domain
                  </LabelUI>
                  <button
                    disabled={(shortenDomains?.length || 0) === 0}
                    onClick={() => setShowDomainDropdown(!showDomainDropdown)}
                    className="text-white w-full bg-pink-600 hover:bg-pink-800 focus:ring-4 focus:outline-none focus:ring-pink-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center "
                    type="button">
                    {formData?.values?.shortenDomain ? formData?.values?.shortenDomain : '--Select--'} <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg></button>

                  <div ref={dropdownDomainMenuWrapper} className={`z-10 w-48 bg-white rounded-lg shadow ${showDomainDropdown ? 'absolute' : 'hidden'}`}>
                    <ul className="p-3 space-y-1 text-sm text-gray-700 max-h-[300px] overflow-y-scroll">
                      {
                        shortenDomains.map((domain, index) => (
                          <li key={index}>
                            <div onClick={() => {
                              onSelectDomain(domain);
                              setShowDomainDropdown(false);
                            }} className="flex items-center cursor-pointer p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                              <label
                                className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                              >
                                {domain}
                              </label>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div className='flex-1'>
            <CoreInput
              formData={formData}
              inputType='textarea'
              fieldKey='content' fieldName='SMS content'
              customClass='min-h-[125px]'
            />
          </div>
        </div>

        <div className='flex space-x-5 mt-4 text-sm mb-5'>
          <div className='flex-1 space-y-2'>
            <div className='font-bold'>Campaing Summary</div>
            <div className='flex flex-row'>
              <span className='mr-2'>Status:</span>
              <MessageStatus status={formData?.values?.status || ''} />
              {
                formData.values.status === EMessageStatus.Scheduled && (
                  <div className='flex flex-row space-x-1 items-center'>
                    <span className='text-xs font-medium mr-2 px-2.5 py-0.5 rounded uppercase border bg-yellow-100 text-yellow-800 border-yellow-400'>
                      {new Date(formData.values.scheduleTime || 0).toLocaleString()}
                    </span>
                    {
                      (formData.values.scheduleTime || 1) - Date.now() > AllowChangeTime && (
                        <>
                          <DatePicker
                            {...((formData.values?.scheduleTime || -1) > 0) && {
                              selected: new Date(formData.values.scheduleTime || 0)
                            }}
                            // @ts-ignore
                            onChange={(date) => formData.setFieldValue('scheduleTime', date?.getTime())}
                            // @ts-ignore
                            onCalendarClose={() => onUpdateTheScheduleTime(formData, formData.values.scheduleTime, false)}
                            minDate={today}
                            showTimeSelect
                            placeholderText='Select schedule time'
                            wrapperClassName=''
                            className='border-none bg-transparent text-sm cursor-pointer w-[230px]'
                            excludeTimes={excludeTime}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            customInput={<span className='text-xs underline text-blue-500 cursor-pointer'>Edit</span>}
                          />
                          <span className='text-xs'>|</span>
                          <span className='text-xs underline text-red-500 cursor-pointer' onClick={() => {
                            if (window.confirm('Do you really want to cancel the schedule?')) {
                              onUpdateTheScheduleTime(formData, -1, true);
                            }
                          }}>Cancel</span>
                        </>
                      )
                    }

                  </div>
                )
              }
              {
                pageLoading === false && formData.values.status === EMessageStatus.Processing && (
                  <div className='cursor-pointer text-[10px] underline text-red-500' onClick={() => handleSyncData(formData)}>Sync and Complete</div>
                )
              }

            </div>
            <div className='flex items-center space-x-2'>
              <div className=''>Content Prepared</div>
              <div className="flex-1 w-full bg-gray-200 rounded-full dark:bg-gray-700">
                <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                  style={{ width: preparedContent }}>
                  {preparedContent}
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-2'>
              <div>Sending Progress</div>
              <div>
                {totalErrorMessages + totalSentMessages}/{totalSendCandidates}
              </div>
              <div className="flex-1 w-full bg-gray-200 rounded-full dark:bg-gray-700 flex">
                {
                  totalSentMessages > 0 && (
                    <div className={`bg-green-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none ${totalErrorMessages > 0 ? 'rounded-l-full' : 'rounded-full'}`}
                      style={{ width: percentageCalculate(totalSentMessages, totalSendCandidates) }}>
                      {percentageCalculate(totalSentMessages, totalSendCandidates)}
                    </div>
                  )
                }
                {
                  totalErrorMessages > 0 && (
                    <div className="bg-red-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-r-full"
                      style={{ width: percentageCalculate(totalErrorMessages, totalSendCandidates) }}>
                      {percentageCalculate(totalErrorMessages, totalSendCandidates)}
                    </div>
                  )
                }
              </div>
            </div>
            {
              totalOptOutUser > 0 && formData.values.status !== EMessageStatus.Sent && (
                <div className='mt-2'>
                  <span
                    onClick={() => setOnlyShowOptOut(!onlyShowOptOutList)}
                    className={`text-xs font-bold mr-2 px-2.5 py-0.5 rounded cursor-pointer ${totalOptoutselected < totalOptOutUser ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                    {`Please exclude ${totalOptoutselected}/${totalOptOutUser} users already opt-out from the list!`}
                  </span>
                </div>
              )
            }
          </div>
          <div className='flex-1 flex flex-col'>
            <div className='font-bold'>Message Preview {`(${numberOfMessage} message(s) - ${formatMessage(formData.values.content, formData?.values?.shortenDomain).length}/${MessageLength * numberOfMessage})`}</div>
            <textarea
              readOnly
              className='italic p-0 text-sm bg-transparent border-none min-h-[125px] text-red-800'
              value={formatMessage(formData.values?.content || '', formData?.values?.shortenDomain)}
              contentEditable={false}
            />
          </div>
        </div>
        <div>
        </div>
        <div className='flex flex-row justify-end my-3'>
          <div className='flex flex-col flex-1 items-end'>
            <div className='flex flex-row w-full justify-end space-x-3'>
              {
                [EMessageStatus.New, EMessageStatus.Draft].includes(formData.values.status) ? (
                  <>
                    <button
                      disabled={pageLoading || [EMessageStatus.New, EMessageStatus.Draft].includes(formData.values.status) === false}
                      type="submit"
                      className="focus:outline-none min-w-[150px] text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:bg-gray-500">
                      Save Draft
                    </button>
                    <button
                      disabled={pageLoading || formData?.values?.status !== EMessageStatus.Draft}
                      type="button"
                      onClick={() => onReadyToSend()}
                      className="focus:outline-none min-w-[150px] text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:bg-gray-500">
                      Ready to Send
                    </button>
                  </>
                ) : (
                  <div className='flex flex-row items-center w-[600px] justify-between text-sm'>
                    <div className='flex flex-row items-center'>
                      {
                        formData.values.status === EMessageStatus.Ready && (
                          <>
                            <span className='mr-2'><input
                              checked={!scheduleSend}
                              onChange={(event) => setSchedule(!event.target.checked)}
                              // onClick={() => setSchedule(!scheduleSend)}
                              type="checkbox"
                              className={`cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
                            /></span>
                            <span>Send this</span>
                            <span className={`ml-1 transition-colors duration-300 ${scheduleSend ? 'line-through' : 'bg-yellow-200'}`}>Right now</span></>
                        )
                      }
                    </div>
                    {
                      scheduleSend && formData.values.status === EMessageStatus.Ready && (
                        <div className='flex-grow pl-2'>
                          <div className={`transition-colors duration-300 ${scheduleSend ? 'bg-yellow-200' : ''}`}>
                            <span className='-mr-2'>At</span>
                            <DatePicker
                              {...((formData.values?.scheduleTime || -1) > 0) && {
                                selected: new Date(formData.values.scheduleTime || 0)
                              }}
                              onChange={(date) => formData.setFieldValue('scheduleTime', date?.getTime())}
                              minDate={today}
                              showTimeSelect
                              placeholderText='Select schedule time'
                              wrapperClassName=''
                              className='border-none bg-transparent text-sm cursor-pointer w-[230px]'
                              excludeTimes={excludeTime}
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          </div>
                        </div>
                      )
                    }

                    <div className='flex justify-end'>
                      <button
                        onClick={() => scheduleSend ? onScheduleMessage(formData) : onSendNow(formData)}
                        disabled={pageLoading || [EMessageStatus.Processing, EMessageStatus.Scheduled, EMessageStatus.Sent].includes(formData.values.status) || (scheduleSend && (formData.values?.scheduleTime || -1) < 0)}
                        type="button"
                        className="focus:outline-none w-[150px] text-white bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:bg-gray-500">
                        {scheduleSend ? 'Set Schedule' : 'Send Now'}
                      </button>
                    </div>
                  </div>
                )
              }
            </div>
          </div>

        </div>
      </form>

      <div className={formData.errors.dataSet ? 'border border-red-600 rounded-md bg-red-100 bg-opacity-50' : ''}>
        <TheTable
          tableData={_uniqBy(tableSet, 'phoneNumber')}
          disableEdit={disableEdit}
          onClickIgnore={onClickIgnore}
          formData={formData}
          onlyShowOptout={onlyShowOptOutList}
          senderPhone={currentUsageSendPhone}
        />
      </div>
    </div>
  )
};

export default memo(TextMessageDetailsPage);
