import React, { memo, useMemo } from 'react';
import { EMessageStatus } from '../models/message';

interface IProps {
  status: EMessageStatus
}

const MessageStatus: React.FC<IProps> = ({ status }) => {
  const classes = useMemo(() => {
    switch (status) {
      case EMessageStatus.Sent:
        return 'bg-green-100 text-green-800 border-green-400';
      case EMessageStatus.Draft:
        return 'bg-gray-100 text-gray-800 border-gray-400';
      case EMessageStatus.Processing:
        return 'bg-red-100 text-red-800 border-red-400';
      case EMessageStatus.Scheduled:
        return 'bg-yellow-100 text-yellow-800 border-yellow-400';
      default:
        return 'bg-blue-100 text-blue-800 border-blue-400';
    }
  }, [status]);
  return (
    <span className={`text-xs font-medium mr-2 px-2.5 py-0.5 rounded uppercase border ${classes}`}>
      {status}
    </span>
  )
};

export default memo(MessageStatus);
