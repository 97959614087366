import React, { memo, useMemo } from 'react';
import ISendAction, { ESendAction } from '../../models/send-action';
import { FormikProps } from 'formik';
import { EMessageStatus } from '../../models/message';
import SendActionStatus from '../send-action-status';
import { useAppContext } from '../../context';

interface IFormData {
  name: string
  description: string
  dataSet: ISendAction[]
  ignoreList: string[]
  content: string
  status: EMessageStatus
  selectedDeId: string[]
  senderPhone: string
  shortenDomain: string
}

interface IProps {
  row: ISendAction
  onClickIgnore: (phoneNumber: string) => void
  disableEdit: boolean
  formData: FormikProps<IFormData>
  senderPhone: string
}

const TableRow: React.FC<IProps> = ({ row, formData, onClickIgnore, disableEdit, senderPhone }) => {
  const { optoutList } = useAppContext();
  const isOptout = useMemo(() => optoutList.findIndex(item => item.phoneNumber.replace('+', '') === row.phoneNumber?.replace('+', '') && item.senderNumber.replace('+', '') === senderPhone?.replace('+', '')) !== -1, [row, optoutList, senderPhone])

  return (
    <tr className="bg-white border-b hover:bg-gray-50">
      <td className="w-4 p-4">
        <div className="flex items-center">
          <input
            onClick={() => onClickIgnore(row.phoneNumber)}
            checked={!(formData?.values?.ignoreList || []).includes(row.phoneNumber)}
            onChange={() => { }}
            disabled={disableEdit}
            type="checkbox"
            className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ${disableEdit ? 'pointer-events-none' : ''}`}
          />
        </div>
      </td>
      <th scope="row" className="min-w-[150px] px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {row.name}
      </th>
      <td className="px-6 py-4">
        {row.phoneNumber}
      </td>
      <td className="px-6 py-4">
        {senderPhone}
      </td>
      <td className="px-6 py-4 min-w-[150px] flex flex-row">
        {/* @ts-ignore */}
        <SendActionStatus row={row} campaign={formData.values} isIgnore={(formData?.values?.ignoreList || []).includes(row.phoneNumber)} />
        {
          isOptout && (
            <span
            className='text-xs font-medium mr-2 px-2.5 py-0.5 rounded uppercase border bg-red-200 text-red-800 border-red-400'>
              {`Opt-out${row.status === ESendAction.Sent ? ' after Sent' : ''}`}
          </span>
          )
        }
      </td>
    </tr>
  )
};

export default memo(TableRow);