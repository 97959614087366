/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';
import _pick from 'lodash/pick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from '../../context';
import { apiInstance } from '../../utils/api-service';
import SmsConversationCard from '../../components/sms-conversation-card';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SmsDisplayCard from '../../components/sms-display-card';
import { toast } from 'react-toastify';
import { formatMessage } from '../../utils/helper';

const MessageLength = 160;

const SMSInbox: React.FC = () => {
  const {
    userToken,
    onStartLoading,
    onEndLoading,
    senderPhones,
    conversations,
    setConversations,
    currentPhoneNumber
  } = useAppContext();
  const [currentText, setcurrentText] = useState('');
  const [conversationFetched, setConversationFetched] = useState(false);
  const [phoneNumberText, setPhoneNumberText] = useState('');
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const params = useParams();
  const conversationId = useMemo(() => {
    if (params?.conversationId === 'new') {
      return 'new';
    }

    try {
      if (params.conversationId) {
        return atob(params.conversationId);
      }

      return '';
    } catch {
      return '';
    }
  }, [params.conversationId]);

  const displayConversation = useMemo(() =>
    conversations.find(conversation => conversation.id === conversationId)
    , [conversationId, conversations]);

  const isNewSMS = useMemo(() => conversationId === 'new', [conversationId]);
  const displayConversations = useMemo(() => {
    if (searchText.length < 3) {
      return conversations;
    }

    return conversations.filter(item => JSON.stringify(_pick(item, ['customerPhoneNumber', 'messages'])).toLocaleLowerCase().includes(searchText.toLowerCase()));
  }, [conversations, searchText]);

  const numberOfMessage = useMemo(() => {
    if (currentText.length) {
      return Math.ceil(formatMessage(currentText).length / MessageLength);
    }

    return 0;
  }, [currentText])

  const debounceFetch = useCallback(_debounce((userToken: string) => {
    onStartLoading();
    apiInstance(userToken)?.get('/conversation').then(res => {
      setConversations(res.data);
      onEndLoading();
      setConversationFetched(true);
    });
  }, 50), []);

  const usageSendPhoneNumber = useMemo(() => displayConversation?.myPhoneNumber || currentPhoneNumber || senderPhones[0], [displayConversation?.myPhoneNumber, senderPhones, currentPhoneNumber]);

  const onSendMessage = useCallback(() => {
    // return alert('Under development');
    /* eslint-disable no-unreachable */
    onStartLoading();
    const sendNumbers = isNewSMS ? phoneNumberText.split(',').map(phone => phone.trim()) : [displayConversation?.customerPhoneNumber];
    apiInstance(userToken)?.put(`/conversation/${encodeURIComponent(displayConversation?.id || 'new')}`, {
      message: currentText,
      from: usageSendPhoneNumber,
      to: sendNumbers
    }).then((res) => {
      onEndLoading();
      if (res.data.status === true) {
        toast('Message Sent!', { type: 'success', autoClose: 300 });
        setcurrentText('');
        debounceFetch(userToken);
      } else {
        toast('Something went wrong!', { type: 'error', autoClose: 300 });
      }
    });
  }, [currentText, displayConversation, userToken, isNewSMS, phoneNumberText, usageSendPhoneNumber]);

  useEffect(() => {
    if (displayConversation && !displayConversation.read) {
      apiInstance(userToken)?.post(`/conversation/${displayConversation.id}`, {
        id: displayConversation.id,
        read: true
      }).then(() => {
        setConversations(conversations.map(item => ({
          ...item,
          read: item.id === displayConversation.id ? true : item.read
        })));
      });
    }
  }, [displayConversation, userToken, conversations]);

  useEffect(() => {
    if (currentPhoneNumber.length > 0 && conversationFetched === false) {
      debounceFetch(userToken);
    }
  }, [userToken, currentPhoneNumber, conversationFetched]);

  useEffect(() => {
    if (currentPhoneNumber.length > 0 && params.conversationId) {
      navigate('/sms-inbox');
    }
  }, [currentPhoneNumber]);

  return (
    <div className=''>
      <div className='flex'>
        <div className='max-w-md w-1/4 h-[calc(100vh-170px)] overflow-y-scroll flex flex-col p-2 divide-y border rounded-md'>
          <div className='flex flex-row mb-2 space-x-2 items-center'>
            <input className='border rounded-md px-3 py-2 text-sm flex-auto'
              placeholder='Search'
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
            />
            <FontAwesomeIcon className='cursor-pointer' onClick={() => debounceFetch(userToken)} icon={faRotate} size='lg' />
            <Link to='/sms-inbox/new' className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-2 py-2.5 disabled:bg-green-400'>
              New
            </Link>
          </div>
          {
            displayConversations.sort((a, b) => {
              if (a.read === b.read) {
                return b.updatedAt - a.updatedAt;
              }

              // @ts-ignore
              return a.read - b.read;
            }).map((item) =>
              <SmsConversationCard
                key={item.id}
                conversation={item}
                selectedId={conversationId}
              />)
          }
        </div>
        <div className='flex-auto bg-gray-50 flex flex-col'>
          <div className={`flex-grow p-2 flex flex-col overflow-y-scroll ${isNewSMS === false ? 'justify-end' : ''}`}>
            {
              isNewSMS && (
                <div className='flex flex-col space-y-2'>
                  <input
                    className='border rounded-md w-full px-5 py-2 text-sm'
                    placeholder='Numbers (ex: number1, number2, number3)'
                    value={phoneNumberText}
                    onChange={(event) => setPhoneNumberText(event.target.value)}
                  />
                  <div className='inline space-x-2'>
                    <span>To:</span>
                    {
                      phoneNumberText.split(',').map((phone, index) => (
                        <span key={index}>{phone?.trim()}</span>
                      ))
                    }
                  </div>
                </div>
              )
            }
            
            <SmsDisplayCard
              conversation={displayConversation}
            />
          </div>
          <div className='flex p-2 space-x-3'>
            <div className='flex flex-col flex-grow space-y-1'>
              <textarea
                disabled={!conversationId}
                value={currentText}
                placeholder='Enter text message'
                className='≈border rounded-md px-5 py-2 disabled:bg-gray-300'
                onChange={(event) => setcurrentText(event.target.value)}
              />
              <span className='text-[12px]'>
                {`(${numberOfMessage} message(s) - ${formatMessage(currentText).length}/${MessageLength * numberOfMessage})`}
              </span>
            </div>
            
            <button
              onClick={() => onSendMessage()}
              disabled={!conversationId || currentText.length === 0}
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 disabled:bg-green-400">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default memo(SMSInbox);
