import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import DataExensionCard from '../../components/data-exension-card';
import { useAppContext } from '../../context';

const DataExtensionPage: React.FC = () => {
  const route = useLocation();
  const { dataExtensions } = useAppContext();

  return (
    <div className='grid grid-cols-5 gap-3'>
      {dataExtensions.sort((a, b) => b.updatedAt - a.updatedAt).map(item => (
        <DataExensionCard key={item.id} data={item} parentPath={route.pathname} />
      ))}
      <DataExensionCard parentPath={route.pathname} />
    </div>
  )
};

export default memo(DataExtensionPage);
