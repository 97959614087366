import React, { memo, useMemo } from 'react';
import ICampaign, { EMessageStatus } from '../models/message';
import ISendAction, { ESendAction } from '../models/send-action';

interface IProps {
  row: ISendAction;
  campaign: ICampaign;
  isIgnore: boolean;
}

const SendActionStatus: React.FC<IProps> = ({ campaign, row, isIgnore }) => {
  // @ts-ignore
  const status: ESendAction = useMemo(() => {
    if (isIgnore) {
      return ESendAction.NotSend;
    }

    if (row.status === ESendAction.Creating) {
      return ESendAction.Creating;
    }

    if (campaign.status === EMessageStatus.Ready) {
      return ESendAction.Ready;
    }

    if (row.status === ESendAction.Processing) {
      return ESendAction.Processing;
    }

    if (campaign.status === EMessageStatus.Scheduled && row.status === ESendAction.Ready) {
      return EMessageStatus.Scheduled;
    }

    return row.status || campaign.status;
  }, [campaign, isIgnore, row]);
  const classes = useMemo(() => {
    switch (status) {
      // @ts-ignore
      case ESendAction.Sent:
      case ESendAction.Processing:
        return 'bg-green-100 text-green-800 border-green-400';
      // @ts-ignore
      case ESendAction.NotSend:
      case ESendAction.Error:
        return 'bg-red-100 text-red-800 border-red-400';
      case ESendAction.Creating:
        return 'bg-yellow-100 text-yellow-800 border-yellow-400';
      default:
        return 'bg-blue-100 text-blue-800 border-blue-400';
    }
  }, [status]);
  return (
    <span className={`text-xs font-medium mr-2 px-2.5 py-0.5 rounded uppercase border ${classes}`}>
      {status}
    </span>
  )
};

export default memo(SendActionStatus);
