import React from 'react';

import {
  Route,
  Routes
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Home from './pages/home';
import LoginPage from './pages/login';
import ErrorPage from './pages/error';
import { AuthenticationGuard } from './components/authentication-guard';
import FullPageLoading from './pages/full-page-loading';
import { menus } from './components/menu';

const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <FullPageLoading />
    );
  }

  return (
    <Routes>
      <Route path='/login' element={<LoginPage />} />
      <Route path="/" element={<Home />}>
        {
          menus.map(item => {
            if (item.detailsComponent) {
              return (
                <React.Fragment key={item.path}>
                  <Route path={item.base} element={item.component} />
                  <Route path={item.path} element={item.detailsComponent} />
                </React.Fragment>
              )
            }

            return (
              (
                <Route key={item.path} path={item.path} element={item.component} />
              )
            )
          })
        }
        <Route path="email" element={<div>Email</div>} />
      </Route>
      <Route
        path="/profile"
        element={<AuthenticationGuard component={<div>Profile Page</div>} />}
      />
      <Route path="/public" element={<div>Public Page</div>} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
