import React, { memo, useEffect } from 'react';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import { useFormik } from 'formik';
import { useAppContext } from '../../context';
import IConfigurationData from '../../models/configuration-data';

const RowData: React.FC<{ data?: IConfigurationData}> = ({ data }) => {
  const { onModifyConfiguration } = useAppContext();
  const formData = useFormik({
    initialValues: data || {
      value: '',
      type: ''
    },
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (data) => onModifyConfiguration(data as IConfigurationData, 'modify')
  });

  useEffect(() => {
    if (data) {
      formData.setValues(data as IConfigurationData);
    }
    // eslint-disable-next-line
  }, [data]);

  return <tr>
    <td>
      <input value={formData.values.value} name='value' onChange={formData.handleChange} />
    </td>
    <td>
      <input className='w-[70px] md:w-auto' value={formData.values.type} name='type' onChange={formData.handleChange} />
    </td>
    <td className='flex flex-row space-x-2 justify-center'>
      <button disabled={_isEqual(formData.values, data) || _isEmpty(formData.values.value) || _isEmpty(formData.values.type)} className='underline cursor-pointer text-blue-600 disabled:text-gray-500 disabled:cursor-not-allowed' onClick={() => formData.handleSubmit()}>
        { typeof data === 'undefined' ? 'Add' : 'Save'}
      </button>
      {
        typeof data !== 'undefined' && (
          <button
            onClick={() => onModifyConfiguration(data, 'delete')}
            className='underline cursor-pointer text-red-600'>Delete</button>
        )
      }
    </td>
  </tr>
};

const ConfigurationPage: React.FC = () => {
  const { configurationData } = useAppContext();

  return (
    <div className='flex flex-col'>
      <table className='w-full'>
        <thead>
          <tr>
            <th>Value</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {configurationData.map((row) => (
            <RowData key={row.id} data={row} />
          ))}
          <RowData />
        </tbody>
      </table>
    </div>
  )
};

export default memo(ConfigurationPage);
