import { createContext, useContext } from 'react';
import IDataExtension, { IOptOut } from '../models/data-extension';
import ICampaign, { IConversation } from '../models/message';
import ITrackingURL from '../models/tracking-url';
import IConfigurationData from '../models/configuration-data';

export type onLoadingAction = () => void;

export interface IAppContext {
  onStartLoading: onLoadingAction
  onEndLoading: onLoadingAction
  pageLoading: boolean
  dataExtensions: IDataExtension[]
  userToken: string
  fetchDataExtension: (token: string, onDone?: () => void) => void
  fetchCampaign: (token: string, onDone?: () => void) => void
  campaigns: ICampaign[]
  senderPhones: string[]
  trackingUrls: ITrackingURL[]
  formatTime: (date: number) => string
  conversations: IConversation[]
  setConversations: (conversations: IConversation[]) => void
  optoutList: IOptOut[]
  currentPhoneNumber: string
  setCurrentPhoneNumber: (phone: string) => void
  getTrackingUrls: (startDate: Date, endDate: Date) => Promise<ITrackingURL[]>
  setTrackingUrls: (startDate: number, endDate: number, data: ITrackingURL[]) => void
  shortenDomains: string[]
  configurationData: IConfigurationData[];
  onModifyConfiguration: (data: IConfigurationData, action: string) => void;
}

export const AppContext = createContext<IAppContext>({
  onStartLoading: () => {},
  onEndLoading: () => {},
  pageLoading: false,
  dataExtensions: [],
  userToken: '',
  fetchDataExtension: () => {},
  fetchCampaign: () => {},
  campaigns: [],
  senderPhones: [],
  trackingUrls: [],
  formatTime: () => '',
  conversations: [],
  setConversations: () => {},
  optoutList: [],
  currentPhoneNumber: '',
  setCurrentPhoneNumber: () => {},
  getTrackingUrls: () => new Promise(resolve => resolve([] as ITrackingURL[])),
  setTrackingUrls: () => {},
  shortenDomains: [],
  configurationData: [],
  onModifyConfiguration: () => {}
});

export const useAppContext = () => useContext(AppContext);
