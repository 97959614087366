import React, { memo, useMemo } from 'react';
import LabelUI from './label';
import InputUI from './input';

interface IProps {
  formData: any
  fieldKey: string
  fieldName: string
  inputType?: string
  customClass?: string
  label?: string
}

const CoreInput: React.FC<IProps> = ({ formData, fieldKey, fieldName, inputType = 'text', customClass, label }) => {
  const error = useMemo(() => formData?.errors?.[fieldKey] || '', [formData, fieldKey]);
  const value = useMemo(() => formData?.values?.[fieldKey] || '', [formData, fieldKey]);
  const isError = useMemo(() => error?.length > 0 && formData?.touched?.[fieldKey] === true, [formData, error, fieldKey]);

  return (
    <div className='flex-1'>
      <LabelUI isError={isError}>
        {`${fieldName}${label ? ` ${label}` : ''}`}
      </LabelUI>
      <InputUI
        name={fieldKey}
        type={inputType}
        onChange={formData.handleChange}
        placeholder={fieldName}
        isError={isError}
        value={value}
        onBlur={formData.handleBlur}
        customClass={customClass}
      />
      <p className={`text-red-500 text-[10px] italic mt-2 transition-opacity duration-100 ${isError ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>{error || <>&nbsp;</>}</p>
    </div>
  )
};

export default memo(CoreInput);
