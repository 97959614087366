import axios from 'axios';

export const apiInstance = (token?: string, basePath = 'api') => {
  if (!token) return;

  return axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE}/${basePath}`,
    ...token && {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });
};

export const uploadFile = async (file: File, folder: string = 'csv'): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      axios.post(`${process.env.REACT_APP_API_BASE}/api/get-upload-url`, {
        fileName: file.name,
        folder,
        fileType: file.type
      }).then(uploadUrl => {
        if (uploadUrl.data && uploadUrl.data.url) {
          axios.put(uploadUrl.data.url, file, {
            headers: {
              'Content-Type': file.type
            }
          }).then(() => {
            resolve(`${folder}/${file.name}`);
          }).catch((e) => reject(e));
        }
      }).catch(e => reject(e));
    } catch (e) {
      reject({});
    }
  });
}
