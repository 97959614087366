import React from 'react';

interface IProps {
  children: React.ReactChild;
  isError?: boolean;
}

const LabelUI: React.FC<IProps> = ({ children, isError }) => (
  <label className={`block mb-2 font-semibold text-sm ${isError ? 'text-red-600' : ''}`}>
    {children}
  </label>
);

export default LabelUI;
