import React, { RefObject, memo, useCallback, useMemo, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import ISendAction from '../../models/send-action';
import { useOnClickOutside } from '../../utils/component-utils';
import { EMessageStatus } from '../../models/message';
import TableRow from './table-row';
import { useAppContext } from '../../context';

interface IFormData {
  name: string
  description: string
  dataSet: ISendAction[]
  ignoreList: string[]
  content: string
  status: EMessageStatus
  selectedDeId: string[]
  senderPhone: string
  shortenDomain: string
}

interface IProps {
  tableData: ISendAction[]
  onClickIgnore: (phoneNumber: string) => void
  disableEdit: boolean
  formData: FormikProps<IFormData>
  onlyShowOptout: boolean
  senderPhone: string
}

const PaginationOptions = [15, 30, 50, 100, 150, 300];
const DefaultItemPerPage = 50;
const PagePaginationButtonDisplaySize = 3;

const TheTable: React.FC<IProps> = ({
  tableData,
  onClickIgnore,
  disableEdit,
  formData,
  onlyShowOptout,
  senderPhone
}) => {
  const [itemPerPage, setItemPerPage] = useState(DefaultItemPerPage);
  const { optoutList } = useAppContext();
  // First page is 0
  const [currentPage, setCurrentPage] = useState(0);
  const [showSelectITemPerPage, setShowSelectItemPerPage] = useState(false);
  const dropdownMenuWrapper: RefObject<HTMLDivElement> = useRef<
    HTMLDivElement
  >() as RefObject<HTMLDivElement>;

  const tableDataFilter = useMemo(() => {
    if (onlyShowOptout) {
      return tableData.filter(item => optoutList.findIndex(
        list => list.phoneNumber?.replace('+', '') === item.phoneNumber?.replace('+', '') &&
        list.senderNumber?.replace('+', '') === senderPhone.replace('+', '')
      ) !== -1)
    }

    return tableData;
  }, [tableData, onlyShowOptout, optoutList, senderPhone]);

  const totalPages = useMemo(() => Math.ceil(tableDataFilter.length / itemPerPage), [tableDataFilter.length, itemPerPage]);

  const firstPagePart = useMemo(() => {
    if (totalPages <= PagePaginationButtonDisplaySize * 3) {
      return Array.from(Array(totalPages || 0).keys());
    }

    return [0, 1, 2];
  }, [totalPages]);

  const lastPageParts = useMemo(() => {
    if (totalPages <= PagePaginationButtonDisplaySize * 3) {
      return [];
    }

    return [totalPages - 3, totalPages - 2, totalPages - 1];
  }, [totalPages]);

  const middlePages = useMemo(() => {
    if (firstPagePart.length >= totalPages) {
      return [];
    }

    if (currentPage <= PagePaginationButtonDisplaySize) {
      return [PagePaginationButtonDisplaySize, PagePaginationButtonDisplaySize + 1, PagePaginationButtonDisplaySize + 2];
    }

    if (currentPage >= totalPages - PagePaginationButtonDisplaySize - 1) {
      return [totalPages - PagePaginationButtonDisplaySize - 3, totalPages - PagePaginationButtonDisplaySize - 2, totalPages - PagePaginationButtonDisplaySize - 1];
    }

    return [currentPage - 1, currentPage, currentPage + 1];
  }, [currentPage, totalPages, firstPagePart]);

  const lastPageItem = useMemo(() => tableDataFilter.length % itemPerPage, [tableDataFilter.length, itemPerPage]);
  const startIndex = useMemo(() => currentPage * itemPerPage, [currentPage, itemPerPage]);
  const endIndex = useMemo(() => {
    if (totalPages === 1) {
      return tableDataFilter?.length;
    }

    const estimateIndex = itemPerPage;

    if (currentPage < totalPages - 1) {
      return estimateIndex + startIndex - 1;
    }

    return startIndex + (estimateIndex > lastPageItem ? lastPageItem : estimateIndex) - 1;
  }, [startIndex, itemPerPage, lastPageItem, currentPage, totalPages, tableDataFilter]);

  const displayItems = useMemo(
    () => tableDataFilter.slice(startIndex, endIndex), [tableDataFilter, startIndex, endIndex]
  );

  useOnClickOutside(dropdownMenuWrapper, () => setShowSelectItemPerPage(false));

  const onChangeItemPerPage = useCallback((newSet: number) => {
    setShowSelectItemPerPage(false);
    setItemPerPage(newSet);
    setCurrentPage(0);
  }, []);

  return (
    <div className="relative shadow-md sm:rounded-lg mt-5">
      <div className="flex items-center justify-between pb-4">
        <div className='relative' ref={dropdownMenuWrapper}>
          <button
            onClick={() => setShowSelectItemPerPage(!showSelectITemPerPage)}
            className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5" type="button">
            Showing {itemPerPage} per page
            <svg
              className="w-2.5 h-2.5 ml-2.5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 10 6"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
            </svg>
          </button>
          <div
            className={`z-10 w-48 bg-white divide-y divide-gray-100 rounded-lg shadow ${showSelectITemPerPage ? 'absolute' : 'hidden'}`}
          >
            <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
              {
                PaginationOptions.map((item, index) => (
                  <li key={index}>
                    <div onClick={() => onChangeItemPerPage(item)} className="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                      <input
                        type="radio"
                        checked={item === itemPerPage}
                        onChange={() => { }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                      <label
                        className="w-full ml-2 text-sm font-medium text-gray-900 rounded">
                        {item} items
                      </label>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>

      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <label>Selected</label>
              </div>
            </th>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Phone Number
            </th>
            <th scope="col" className="px-6 py-3">
              Sender Phone
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {
            displayItems.map((row: ISendAction, index: number) => (
              <TableRow
                key={index}
                row={row}
                disableEdit={disableEdit}
                onClickIgnore={onClickIgnore} formData={formData}
                senderPhone={senderPhone}
              />
            ))
          }
        </tbody>
      </table>

      <nav className={`flex items-center justify-between pt-4 ${tableData?.length <= itemPerPage ? 'opacity-0 pointer-events-none' : ''}`}>
        <span
          className="text-sm font-normal text-gray-500">
          Showing
          <span className="font-semibold text-gray-900 mx-2">{startIndex + 1}-{endIndex + 1}</span>
          of
          <span className="font-semibold text-gray-900 ml-2" >{tableDataFilter.length || 0}</span>
        </span>
        {
          totalPages > 1 && (
            <ul className="inline-flex -space-x-px text-sm h-8 items-center">
              <li>
                <button
                  disabled={currentPage === 0}
                  onClick={() => setCurrentPage(currentPage - 1)}
                  className={
                    `flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 ${currentPage === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`
                  }>
                  Previous
                </button>
              </li>

              {
                firstPagePart.map(pageItem => {
                  const isActive = pageItem === currentPage;

                  return (
                    <li key={`page-item-${pageItem}`}>
                      <div
                        onClick={() => setCurrentPage(pageItem)}
                        className={
                          `flex cursor-pointer items-center justify-center px-3 h-8 leading-tight border border-gray-300 ${isActive ? 'text-blue-600 hover:text-blue-700 bg-blue-50 hover:bg-blue-100' : 'text-gray-500 hover:bg-gray-100 bg-white hover:text-gray-700'}`
                        }>
                        {pageItem + 1}
                      </div>
                    </li>
                  )
                })
              }

              {
                firstPagePart[firstPagePart.length - 1] + 1 < middlePages[0] && (
                  <li>
                    <div className='mx-2'>...</div>
                  </li>
                )
              }

              {
                middlePages.map(pageItem => {
                  const isActive = pageItem === currentPage;

                  return (
                    <li key={`page-item-${pageItem}`}>
                      <div
                        onClick={() => setCurrentPage(pageItem)}
                        className={
                          `flex cursor-pointer items-center justify-center px-3 h-8 leading-tight border border-gray-300 ${isActive ? 'text-blue-600 hover:text-blue-700 bg-blue-50 hover:bg-blue-100' : 'text-gray-500 hover:bg-gray-100 bg-white hover:text-gray-700'}`
                        }>
                        {pageItem + 1}
                      </div>
                    </li>
                  )
                })
              }

              {
                middlePages[middlePages.length - 1] + 1 < lastPageParts[0] && (
                  <li>
                    <div className='mx-2'>...</div>
                  </li>
                )
              }

              {
                lastPageParts.map(pageItem => {
                  const isActive = pageItem === currentPage;

                  return (
                    <li key={`page-item-${pageItem}`}>
                      <div
                        onClick={() => setCurrentPage(pageItem)}
                        className={
                          `flex cursor-pointer items-center justify-center px-3 h-8 leading-tight border border-gray-300 ${isActive ? 'text-blue-600 hover:text-blue-700 bg-blue-50 hover:bg-blue-100' : 'text-gray-500 hover:bg-gray-100 bg-white hover:text-gray-700'}`
                        }>
                        {pageItem + 1}
                      </div>
                    </li>
                  )
                })
              }
              <li>
                <button
                  disabled={currentPage === totalPages - 1}
                  onClick={() => setCurrentPage(currentPage + 1)}
                  className={
                    `flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 ${currentPage === totalPages - 1 ? 'cursor-not-allowed' : 'cursor-pointer'}`
                  }>
                  Next
                </button>
              </li>
            </ul>
          )
        }

      </nav>
    </div>
  )
};

export default memo(TheTable);