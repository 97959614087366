/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import csvToJson from 'csvtojson';
import * as Yup from 'yup';
import CoreInput from '../../components/core/core-input';
import LabelUI from '../../components/core/label';
import { apiInstance } from '../../utils/api-service';
import { toast } from 'react-toastify';
import { useAppContext } from '../../context';
import { useNavigate, useParams } from 'react-router-dom';
import ISendAction from '../../models/send-action';

interface IFormData {
  name?: string
  description?: string
  csvFile?: File
  dataSet?: ISendAction[]
  id?: string
}

interface ITableRow {
  name: string
  phoneNumber: string
}

const initialData: IFormData = {
  name: '',
  description: ''
}

const DataExtensionDetailsPage: React.FC = () => {
  const [table, setTable] = useState<ITableRow[]>([]);
  const { onStartLoading, onEndLoading, userToken, fetchDataExtension, dataExtensions } = useAppContext();
  const navigate = useNavigate();
  const params = useParams();

  const onSubmitForm = useCallback((formValues: IFormData) => {
    onStartLoading();
    const isUpdating = (formData.values?.id?.length || 0) > 0;

    apiInstance(userToken)?.post('/data-extension', {
      dataSet: table,
      name: formValues.name,
      description: formValues.description,
      ...isUpdating && { id: formData.values.id }
    }).then(() => {
      fetchDataExtension(userToken, () => {
        toast(`Data Extension is succesfully ${!isUpdating ? 'updated' : 'created'}`, { type: 'success', autoClose: 300 });
        onEndLoading();
        navigate('/data-extension');
      });
    });
  }, [onStartLoading, onEndLoading, userToken, table, navigate, fetchDataExtension]);

  const formData = useFormik({
    initialValues: initialData,
    validationSchema: Yup.object({
      name: Yup.string().required('Please fill the data extension name'),
      description: Yup.string().required('Please fill the data extension description'),
      dataSet: Yup.array().min(1).required('Please make sure data set is not empty')
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: onSubmitForm
  });

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    formData.setFieldValue('csvFile', event.target.files?.[0])
  }, [formData]);

  const readFileToString = useCallback((file: File) => {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target?.result?.toString());
      }

      reader.readAsText(file);
    });
  }, []);

  const onRemoveDE = useCallback(() => {
    if (window.confirm('Do you really want to delete this?')) {
      onStartLoading();
      apiInstance(userToken)?.delete(`/data-extension/${params.dataId}`).then(() => {
        fetchDataExtension(userToken, () => {
          toast('Data Extension is succesfully deleted', { type: 'success', autoClose: 300 });
          onEndLoading();
          navigate('/data-extension');
        });
      });
    }
  }, [params.dataId, userToken]);

  useEffect(() => {
    if (formData?.values?.csvFile) {
      // @ts-ignore
      readFileToString(formData.values.csvFile).then((text) => csvToJson({ delimiter: ';' }).fromString(text).then(rows => {
        setTable(rows);
        formData.setFieldValue('dataSet', rows);
      }))
    }
  }, [formData?.values, readFileToString]);

  useEffect(() => {
    if (params.dataId) {
      const currentDE = dataExtensions.find(item => item.id === params.dataId);

      if (currentDE) {
        // @ts-ignore
        formData.setValues(currentDE);
        if (currentDE.dataSet?.length > 0) {
          setTable(currentDE.dataSet);
        }
      }
    }
  }, [dataExtensions, params.dataId]);

  return (
    <div className='flex flex-col'>
      <form onSubmit={formData.handleSubmit}>
        <CoreInput formData={formData} fieldKey='name' fieldName='Name' />
        <CoreInput formData={formData} fieldKey='description' fieldName='Description' />
        <LabelUI isError={formData?.errors?.dataSet ? true : false}>
          Upload CSV file
        </LabelUI>
        <input
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          accept='.csv'
          name='csvFile' type="file" onChange={handleFileChange} />
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">.CSV File only.</p>
        <p className={`text-red-500 text-[10px] italic mt-2 transition-opacity duration-100 ${formData?.errors?.dataSet ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>{formData?.errors?.dataSet || <>&nbsp;</>}</p>
        <div className='flex flex-row justify-end my-3 space-x-3'>
          <button
            type="submit"
            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5">
            Save
          </button>
          {
            params.dataId && params.dataId !== 'new' && (
              <button
                onClick={() => onRemoveDE()}
                type="button"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5">
                Delete
              </button>
            )
          }
        </div>
      </form>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Phone Number
              </th>
            </tr>
          </thead>
          <tbody>
            {
              table.map((row, index) => (
                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {row.name}
                  </th>
                  <td className="px-6 py-4">
                    {row.phoneNumber}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
};

export default memo(DataExtensionDetailsPage);
