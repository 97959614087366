import ISendAction from './send-action';

export enum EMessageStatus {
  New = 'composing',
  Ready = 'ready',
  Draft = 'draft',
  Sent = 'sent',
  Scheduled = 'scheduled',
  Processing = 'processing'
}

export default interface ICampaign {
  id: string
  name: string
  description: string
  dataSet: ISendAction[]
  ignoreList: string[]
  content: string
  status: EMessageStatus
  selectedDeId: string[]
  createdBy: string
  createdAt: number
  updatedBy: string
  updatedAt: number
  scheduleTime?: number
  senderPhone: string
  clickRate: number
  totalSent: number
  totalRow: number
  trackingIds: string[]
  tempClick?: number
}

export interface IMessage {
  message: string
  phone: string
  sentAt: number
}

export interface IConversation {
  createdAt: number
  createdBy: string
  customerPhoneNumber: string
  myPhoneNumber: string
  read: boolean
  updatedAt: number
  id: string
  messages: IMessage[]
}
