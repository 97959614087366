/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import _debouce from 'lodash/debounce';
import { getCurrentWeek } from '../utils/helper';

interface IProps {
  onChange: (startDate: Date, endDate: Date) => void;
}

const DateRange: React.FC<IProps> = ({ onChange }) => {
  const [showAlert, setShowAlert] = useState(true);
  const [dateRange, setDateRange] = useState(getCurrentWeek());
  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

  // eslint-disable-next-line
  const postUpdate = useCallback(_debouce((startDate: Date, endDate: Date) => onChange(startDate, endDate), 1000), []);

  useEffect(() => {
    if (startDate && endDate) {
      postUpdate(startDate, endDate);

      const days = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
      if (days > 31) {
        setShowAlert(true);
      }
    }
  }, [startDate, endDate]);

  return (
    <div className='flex flex-col sm:flex-row justify-between mb-5 items-center'>
      <div className={`p-4 mb-3 sm:mb-0 text-sm text-yellow-800 rounded-lg bg-yellow-50 shadow-lg transition-opacity duration-150 ${showAlert ? '' : 'opacity-0 h-0'}`}>
        <span className="font-medium">For Page Performance!</span> Please don't try to lookup data more than 15 days.
        <button
          onClick={() => setShowAlert(false)}
          type="button"
          className="ml-2 -mx-1 -my-1.5 bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex items-center justify-center h-6 w-6 "
          aria-label="Close">
          <span className="sr-only">Close</span>
          <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </button>
      </div>
      <div className='flex items-center space-x-2'>
        <div className='font-bold'>Date Range</div>
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            // @ts-ignore
            const [startDate, endDate] = update;
            endDate?.setHours(23);
            endDate?.setMinutes(59);
            endDate?.setSeconds(59);
            endDate?.setMilliseconds(999);
            // @ts-ignore
            setDateRange([startDate, endDate]);
          }}
          isClearable={true}
          placeholderText='Select Date Range'
          className='border-none w-[250px]'
        />
      </div>

    </div>
  )
};

export default memo(DateRange);