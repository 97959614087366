import React, { memo, useMemo } from 'react';
import { IConversation } from '../models/message';
import { Link } from 'react-router-dom';

interface IProps {
  conversation: IConversation
  selectedId: string
}

const SMSConversationCard: React.FC<IProps> = ({ conversation, selectedId }) => {
  const pickedMessage = useMemo(() => conversation.messages.sort((a, b) => b.sentAt - a.sentAt)[0], [conversation]);

  return (
    <Link
      to={`/sms-inbox/${btoa(conversation.id)}`}
      className={`cursor-pointer py-2 hover:bg-red-50 transition-colors duration-100 ${conversation.id === selectedId ? 'bg-red-50' : ''}`}>
      <div className={`text-[15px] flex flex-row space-x-2 items-center ${conversation.read ? '' : 'font-bold'}`}>
        <span>Phone: +{conversation.customerPhoneNumber}</span>
        <span className='text-[12px] font-normal'>({conversation.messages.length})</span>
        {
          !conversation.read && (
            <div className='w-3 h-3 bg-red-700 rounded-full'></div>
          )
        }
      </div>
      <div className='text-[12px]'>
        {pickedMessage.message}
      </div>
    </Link>
  )
};

export default memo(SMSConversationCard);
