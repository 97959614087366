import React from 'react';

type IProps = React.HTMLProps<HTMLInputElement> & {
  isError?: boolean;
  customClass?: string;
}

const InputUI: React.FC<IProps> = (props) => {
  const { isError, ...others }: IProps = props;

  if (props.type === 'textarea') {
    return (
      // @ts-ignore
      <textarea
        className={`appearance-none block w-full text-gray-700 border border-gray-400 text-sm rounded-lg py-2 px-4 focus:outline-none border-opacity-50 focus:border-opacity-100 ${props.isError === true ? 'border-red-400 bg-red-50 text-red-500' : 'border-gray-200'} ${props.customClass && props.customClass}`}
        {...others}
      />
    )
  }

  return (
    (
      <input
        className={`appearance-none block w-full text-gray-700 border border-gray-400 text-sm rounded-lg py-2 px-4 focus:outline-none border-opacity-50 focus:border-opacity-100 ${props.isError === true ? 'border-red-400 bg-red-50 text-red-500' : 'border-gray-200'}`}
        {...others}
      />
    )
  )
};

export default InputUI;
