export enum ESendAction {
  New = 'new',
  Ready = 'ready',
  Creating = 'creating',
  Sent = 'sent',
  Scheduled = 'scheduled',
  Processing = 'processing',
  NotSend = 'not send',
  Error = 'error'
}

export default interface ISendAction {
  id?: string
  name: string
  campaignId: string
  phoneNumber: string
  senderPhone: string
  content: string
  trackingIds?: string[]
  status: ESendAction
}
