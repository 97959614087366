import React, { memo } from 'react';
import { IConversation } from '../models/message';
import MessageInboxRow from './message-inbox-row';

interface IProps {
  conversation?: IConversation
}

const SMSDisplayCard: React.FC<IProps> = ({ conversation }) => {
  return (
    <div className='flex flex-col space-y-3'>
      {
        conversation?.messages.sort((a, b) => a.sentAt - b.sentAt).map((message, index) => (
          <MessageInboxRow
            key={`${conversation.id}-${index}`}
            customerPhone={conversation.customerPhoneNumber}
            message={message}
          />
        ))
      }
    </div>
  )
};

export default memo(SMSDisplayCard);
