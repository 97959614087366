/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from 'react';
import _uniqBy from 'lodash/uniqBy';
import { useAppContext } from '../../context';
import { Link, useLocation } from 'react-router-dom';
import CampaignCard from '../../components/campaign-card';
import DateRange from '../../components/date-range';
import { apiInstance } from '../../utils/api-service';
import ICampaign from '../../models/message';
import ITrackingURL from '../../models/tracking-url';

const TextMessagePage: React.FC = () => {
  const route = useLocation();
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [isLoading, setIsLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [currentView, setCurrentView] = useState(0);
  const [trackingUrls, setTrackingUrls] = useState<ITrackingURL[]>([]);
  const { onStartLoading, onEndLoading, userToken, getTrackingUrls } = useAppContext();
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);

  const onDeleteCampaign = useCallback((id: string) => {
    setCampaigns(currents => currents.filter(item => item.id !== id));
  }, [setCampaigns]);

  const onFetchCampaigns = useCallback((startDate: Date, endDate: Date, currentView: number) => {
    if (userToken) {
      onStartLoading();
      setIsLoading(true);
      apiInstance(userToken)?.get('/campaign', { params: { startDate: startDate.getTime(), endDate: endDate.getTime(), viewSet: currentView } })
        .then(res => {
          const { totalSet, results = [] } = res.data;

          setCampaigns(current => _uniqBy([...current, ...results], 'id'));
          setMaxPage(totalSet);

          getTrackingUrls(startDate, endDate).then((urls) => {
            setTrackingUrls(urls);
            onEndLoading();
            setIsLoading(false);
          })
        });
    }
  }, [setCampaigns, userToken, getTrackingUrls]);

  useEffect(() => {
    const { startDate, endDate } = dateRange;

    if (startDate !== null && endDate !== null && userToken) {
      onFetchCampaigns(startDate, endDate, 0);
    }
  }, [dateRange]);

  return (
    <div className='flex flex-col space-y-5'>
      <DateRange onChange={(startDate, endDate) => {
        // @ts-ignore
        setDateRange({ startDate, endDate });
        setCurrentView(0);
        setMaxPage(1);
        setCampaigns([]);
      }} />

      <div className='flex justify-end items-center space-x-3'>
        <Link className='capitalize focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5' to={`${route.pathname}/new`}>
          Create campaign
        </Link>
      </div>
      {campaigns.sort((a, b) => b.updatedAt - a.updatedAt).map(item => (
        <CampaignCard
          // @ts-ignore
          dateRange={dateRange}
          key={item.id}
          parentPath={route.pathname}
          data={item}
          trackingUrls={trackingUrls}
          onDeleteCampaignAction={onDeleteCampaign}
        />
      ))}
      {
        currentView < maxPage - 1 && (
          <button
            disabled={isLoading}
            className='capitalize focus:outline-none text-white bg-green-700 hover:bg-green-800 disabled:bg-gray-400 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5' onClick={() => {
              const { startDate, endDate } = dateRange;

              if (startDate !== null && endDate !== null) {
                onFetchCampaigns(startDate, endDate, currentView + 1);
                setCurrentView(currentView + 1);
              }
            }}>Load More</button>
        )
      }
    </div>
  )
};

export default memo(TextMessagePage);
