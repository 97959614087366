import React, { memo, useMemo } from 'react';
import { IMessage } from '../models/message';
import { useAppContext } from '../context';

interface IProps {
  message: IMessage
  customerPhone: string
}

const MessageInboxRow: React.FC<IProps> = ({ message, customerPhone }) => {
  const { formatTime } = useAppContext();
  const isMine = useMemo(() => message.phone.replace('+', '') !== customerPhone.replace('+', ''), [message.phone, customerPhone]);

  return (
    <div className=''>
      <div className={`flex flex-col ${isMine ? 'text-right' : ''}`}>
        <div className='mb-1'>
          <span className='font-bold mr-2'>{message.phone}</span>
          <span>{formatTime(message.sentAt)}</span>
        </div>
        <textarea
          readOnly
          value={message.message}
          className={`border-none text-[14px] overflow-hidden min-h-[100px] w-1/2 rounded-md shadow-lg ${isMine ? 'self-end bg-green-200' : 'bg-white'}`}
        />
      </div>
    </div>
  )
};

export default memo(MessageInboxRow);