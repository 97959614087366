import { ReactElement } from 'react';
import Home from '../pages/home/index';
import TextMessage from '../pages/home/text-message';
import DataExtensionPage from '../pages/home/data-extension-page';
import DataExtensionDetailsPage from '../pages/home/data-extension-details-page';
import TextMessageDetailsPage from '../pages/home/text-message-details-page';
import SmsInbox from '../pages/home/sms-inbox';
import ConfigurationPage from '../pages/home/configuration-page';

export interface ILinkMenu {
  base?: string
  path: string
  component: ReactElement
  detailsComponent?: ReactElement
  text: string
}

export const menus: ILinkMenu[] = [
  {
    path: '/',
    component: <Home />,
    text: 'Home'
  },
  {
    base: 'data-extension',
    path: 'data-extension/:dataId?',
    component: <DataExtensionPage />,
    detailsComponent: <DataExtensionDetailsPage />,
    text: 'Data Extensions'
  },
  {
    base: 'text-message',
    path: 'text-message/:projectId?',
    component: <TextMessage />,
    detailsComponent: <TextMessageDetailsPage />,
    text: 'SMS Campaign'
  },
  {
    base: 'sms-inbox',
    path: 'sms-inbox/:conversationId?',
    component: <SmsInbox />,
    text: 'SMS Inbox'
  },
  {
    base: 'configuration',
    path: 'configuration',
    component: <ConfigurationPage />,
    text: 'Configuration'
  }
];
