import React, { memo, useMemo } from 'react';

interface IProps {
  currentPercentage: number;
  ranges: number[];
  text: string;
  higherIsBad?: boolean;
}

const colorLists = ['bg-green-700', 'bg-blue-700', 'bg-yellow-700', 'bg-red-700', 'bg-gray-700', 'bg-black'];

const StatisticLabel: React.FC<IProps> = ({ currentPercentage, text, ranges, higherIsBad = false }) => {
  const colors = useMemo(() => {
    const candidate = Math.ceil(currentPercentage);

    if (candidate === ranges[ranges.length - 1]) {
      return higherIsBad ? ranges.length - 1 : 0;
    }

    for (let i = 0; i < ranges.length - 1; i++) {
      if (candidate >= ranges[i] && candidate <= ranges[i + 1]) {
        return higherIsBad ? i : ranges.length - 1 - i;
      }
    }
    // If the number is greater than or equal to the last element, return the last index
    if (candidate >= ranges[ranges.length - 1]) {
      return higherIsBad ? ranges.length - 1 : 0;
    }
    return higherIsBad ? 0 : ranges.length - 1; // Return -1 if the number is not f
  }, [currentPercentage, ranges, higherIsBad]);

  return (
    <div className='flex flex-row items-center'>
      <div className={`w-[38px] h-[38px] text-white font-bold mr-2 items-center justify-center text-center flex text-xs rounded-md ${colorLists[colors]}`}>
        {currentPercentage.toFixed(0)}%
      </div>
      <div className='text-xs font-semibold'>{text}</div>
    </div>
  )
};

export default memo(StatisticLabel);