import React, { memo, useMemo } from 'react';

interface IProps {
  number: string | number;
  text: string
}

const randomClass = [
  'bg-red-700',
  'bg-blue-700',
  'bg-purple-700',
  'bg-green-700',
  'bg-pink-700',
  'bg-orange-700',
  'bg-amber-700',
  'bg-cyan-700',
  'bg-yellow-700',
  'bg-indigo-700',
  'bg-rose-700',
  'bg-violet-700',
  'bg-fuchsia-700'
]

function randomIntFromInterval(min: number, max: number) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const StaticalCard: React.FC<IProps> = ({ number, text }) => {
  const displayNumber = useMemo(() => {
    if (typeof number === 'string') {
      return number;
    }

    if (number > 10000) {
      return `${Math.floor(number / 1000)}k`;
    }

    return number;
  }, [number]);

  return (
    <div className='bg-white h-[100px] rounded-md shadow-md hover:shadow-lg transition-shadow duration-300 flex overflow-hidden items-center'>
      <div className={`h-[100px] w-[100px] flex justify-center items-center text-[30px] text-white font-bold p-5 ${randomClass[randomIntFromInterval(0, randomClass.length - 1)]}`}>
        {displayNumber}
      </div>
      <div className='pl-5 flex-auto text-[25px]'>
        {text}
      </div>
    </div>
  )
};

export default memo(StaticalCard);
