/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  PointElement,
  LineElement, Title
} from 'chart.js';
import _groupBy from 'lodash/groupBy';
import _padStart from 'lodash/padStart';
import _sumBy from 'lodash/sumBy';
import _map from 'lodash/map';
import { Doughnut, Line } from 'react-chartjs-2';
import StaticalCard from '../../components/statical-card';
import ICampaign, { EMessageStatus } from '../../models/message';
import { useAppContext } from '../../context';
import DateRange from '../../components/date-range';
import ITrackingURL from '../../models/tracking-url';
import { apiInstance } from '../../utils/api-service';
import { calculateTotalClickByCampaign } from '../../utils/helper';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title);

const options = {
  responsive: true,
  tension: 0.2,
  plugins: {
    title: {
      display: true,
      text: 'Tracking URLs',
    },
  },
};

const formatADate = (dateAsNumber: number) => {
  const theDate = new Date(dateAsNumber);

  return `${_padStart(`${theDate.getDate()}`, 2, '0')}-${_padStart(`${theDate.getMonth() + 1}`, 2, '0')}-${theDate.getFullYear()}`;
}

const HomeIndex: React.FC = () => {
  const { userToken, onStartLoading, onEndLoading, getTrackingUrls } = useAppContext();
  const [trackingUrlsHome, setTrackingUrls] = useState<ITrackingURL[]>([]);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const homeData: ICampaign[] = useMemo(() => {
    return campaigns.map(campaign => ({
      ...campaign,
      tempClick: calculateTotalClickByCampaign(campaign.trackingIds, trackingUrlsHome)
    }))
  }, [campaigns, trackingUrlsHome]);

  const totalTrackingUrls = useMemo(() => _sumBy(homeData, 'trackingIds.length'), [homeData]);

  const urlGroupedByDate = useMemo(() => _groupBy(homeData, url => formatADate(url.updatedAt)), [homeData]);
  const dataSet = useMemo(() => {
    return _map(urlGroupedByDate, (value, key) => ({
      date: key,
      data: value,
      totalClicks: _sumBy(value, 'tempClick')
    })).sort((a, b) => {
      const vnDateFormat = (date: string) => {
        const [day, month, year]: string[] = date.split('-');
        return new Date(`${parseInt(month, 10) - 1}-${day}-${year}`).getTime();
      }

      return vnDateFormat(a.date) - vnDateFormat(b.date);
    });
  }, [urlGroupedByDate]);

  const clickRate = useMemo(() => {
    if (homeData.length === 0) {
      return 0;
    }

    return _sumBy(homeData, 'tempClick') / _sumBy(homeData, 'trackingIds.length');
  }, [homeData]);

  useEffect(() => {
    const { startDate, endDate } = dateRange;

    if (startDate !== null && endDate !== null && userToken) {
      onStartLoading();

      const requestSet = [
        // @ts-ignore
        getTrackingUrls(startDate, endDate).then(trackingUrlResponse => {
          setTrackingUrls(trackingUrlResponse);
        }),
        // @ts-ignore
        apiInstance(userToken)?.get('/campaign', { params: { startDate: startDate.getTime(), endDate: endDate.getTime(), forHomePage: true } })
        .then(res => setCampaigns(res.data))
      ];

      Promise.all(requestSet).then(() => onEndLoading());
    }
  }, [userToken, dateRange]);

  return (
    <div className='flex flex-col'>
      {/* @ts-ignore */}
      <DateRange onChange={(startDate, endDate) => setDateRange({ startDate, endDate })} />
      <div className='flex flex-col sm:flex-row space-x-0 sm:space-x-5 space-y-3 sm:space-y-0 items-center'>
        <div className='sm:flex-1 w-full sm:w-auto'>
          <StaticalCard text='Campaign' number={campaigns.length} />
        </div>
        <div className='sm:flex-1 w-full sm:w-auto'>
          <StaticalCard text='Click Rate' number={`${(clickRate * 100).toFixed(0)}%`} />
        </div>
        <div className='sm:flex-1 w-full sm:w-auto'>
          <StaticalCard text='Tracking URLs' number={totalTrackingUrls} />
        </div>
        <div className='sm:flex-1 w-full sm:w-auto'>
          <StaticalCard text='Scheduled Campaign' number={campaigns?.filter(item => item.status === EMessageStatus.Scheduled)?.length} />
        </div>
      </div>
      <div className='flex flex-col sm:flex-row space-x-0 sm:space-x-5 space-y-5 sm:space-y-0 mt-10'>
        <div className='sm:flex-1 flex flex-col'>
          <Doughnut options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: 'Campaigns',
              },
            },
          }} data={{
              labels: [
                EMessageStatus.Draft,
                EMessageStatus.Ready,
                EMessageStatus.Sent,
                EMessageStatus.Scheduled
              ],
              datasets: [
                {
                  label: 'Total',
                  data: [
                    campaigns.filter(item => item.status === EMessageStatus.Draft).length,
                    campaigns.filter(item => item.status === EMessageStatus.Ready).length,
                    campaigns.filter(item => item.status === EMessageStatus.Sent).length,
                    campaigns.filter(item => item.status === EMessageStatus.Scheduled).length
                  ],
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                  ],
                  borderWidth: 1,
                },
              ],
          }} />
        </div>
        <div className='sm:flex-1 flex flex-col'>
          <Line options={options} data={{
            labels: dataSet.map(item => item.date),
            datasets: [
              {
                label: 'URL',
                data: dataSet.map(item => _sumBy(item.data, 'trackingIds.length')),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
              {
                label: 'Clicks',
                data: dataSet.map(item => item.totalClicks),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ],
          }} />
        </div>
      </div>
    </div>
  )
};

export default memo(HomeIndex);
