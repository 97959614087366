import React, { memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const Login: React.FC = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  if (isAuthenticated) {
    return <Navigate replace to='/' />
  }

  return (
    <div className='flex justify-center items-center bg-gray-50 h-screen'>
      <button
        onClick={() => loginWithRedirect()}
        className='bg-red-800 px-10 py-2 rounded-md text-white'>You have account?</button>
    </div>
  )
}

export default memo(Login);
