import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import ICampaign, { EMessageStatus } from '../models/message';
import MessageStatus from './message-status';
import { useAppContext } from '../context';
import StatisticLabel from './statistic-label';
import { ESendAction } from '../models/send-action';
import { calculateTotalClick } from '../utils/helper';
import ITrackingURL from '../models/tracking-url';
import { apiInstance } from '../utils/api-service';

interface IProps {
  data: ICampaign
  parentPath: string
  dateRange: { startDate: Date, endDate: Date }
  trackingUrls: ITrackingURL[]
  onDeleteCampaignAction: (id: string) => void
}

const todayLink = new Date().toJSON().slice(0, 10);

const CampaignCard: React.FC<IProps> = ({ data, parentPath, dateRange, trackingUrls, onDeleteCampaignAction }) => {
  const { formatTime, optoutList, senderPhones, getTrackingUrls, onStartLoading, onEndLoading, userToken, dataExtensions } = useAppContext();
  const [dataToDownload, setDataToDownload] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const theDownloadBtn = useRef();
  const sentItems = useMemo(() => (data?.dataSet || []).filter(row => (row.status === ESendAction.Sent || (data.status === EMessageStatus.Sent && row.status === ESendAction.Ready))), [data.dataSet, data.status]);
  const senderNumber = useMemo(() => data?.senderPhone || senderPhones[0], [data.senderPhone, senderPhones]);
  const totalOptout = useMemo(() =>
    sentItems.filter(item => optoutList.findIndex(
      list => list.phoneNumber?.replace('+', '') === item.phoneNumber?.replace('+', '') &&
        list.senderNumber?.replace('+', '') === senderNumber.replace('+', '')
    ) !== -1), [sentItems, optoutList, senderNumber]);

  const rowHasClicked = useMemo(() => {
    return sentItems.filter(item => calculateTotalClick(item, trackingUrls) > 0).length;
  }, [sentItems, trackingUrls]);
  const onClickDownload = useCallback(async () => {
    const { startDate, endDate } = dateRange;

    if (startDate !== null && endDate !== null) {
      setDownloadLoading(true);
      const trackingUrls = await getTrackingUrls(startDate, endDate);
      const header = ['name', 'phoneNumber', 'fromNumber', 'status', 'click', 'opt-out'];

      // @ts-ignore
      setDataToDownload([header, ...(data.dataSet || []).map(row => [
        row.name || 'No name',
        row.phoneNumber,
        senderNumber,
        row.status || 'skip send',
        calculateTotalClick(row, trackingUrls),
        row.status === ESendAction.Sent &&
          optoutList.findIndex(item => item.phoneNumber.replace('+', '') === row.phoneNumber?.replace('+', '') && item.senderNumber.replace('+', '') === senderNumber.replace('+', '')) !== -1 ? 'yes' : 'no'
      ])]);

      setTimeout(() => {
        // @ts-ignore
        theDownloadBtn?.current?.link.click();
        setDownloadLoading(false);
      }, 10);
    }
  }, [data.dataSet, theDownloadBtn, dateRange, getTrackingUrls, senderNumber, optoutList]);

  const selectedDEs = useMemo(() => {
    return dataExtensions.filter(item => (data?.selectedDeId || []).includes(item.id));
  }, [dataExtensions, data.selectedDeId]);

  const onActionDelete = useCallback(() => {
    onStartLoading();
    apiInstance(userToken)?.post('/campaign', { id: data.id, deleted: true }).then(() => {
      onEndLoading();
      toast('Deleted succesfully!', { type: 'success', autoClose: 500 });
      onDeleteCampaignAction(data.id);
    });
  }, [onStartLoading, onEndLoading, userToken, data.id, onDeleteCampaignAction]);

  const onDeleteCampaign = useCallback(() => {
    if (data.status === EMessageStatus.Scheduled) {
      toast('Please remove schedule first!', { type: 'error', autoClose: 500 });
    } else {
      const compareMessage = 'please help me to delete this';
      const message = window.prompt(`Please type "${compareMessage}" to delete`);

      if (message === compareMessage) {
        onActionDelete();
      }
    }
  }, [data, onActionDelete]);

  return (
    <div
      className='flex flex-col sm:flex-row bg-white border border-gray-200 rounded-md shadow p-3 hover:shadow-lg transition-shadow duration-300'
    >
      <div className='flex-1 flex flex-col'>
        <Link to={`${parentPath}/${data?.id || 'new'}`}>
          <h1 className='font-bold text-lg capitalize flex flex-row items-center hover:text-green-800'>
            <span className='mr-2'>{data?.name}</span>
            <MessageStatus status={data?.status} />

            {
              (data.scheduleTime || -1) > 0 && (
                <span className='text-xs font-medium mr-2 px-2.5 py-0.5 rounded uppercase border bg-yellow-100 text-yellow-800 border-yellow-400'>
                  {new Date(data.scheduleTime || 0).toLocaleString()}
                </span>
              )
            }
          </h1>
        </Link>
        <div className='text-sm text-gray-600 mb-3'>{data?.description}</div>
        <div className='text-md font-semibold'>
          <span className='mr-2'>
            Total User: {data.totalRow}
          </span>
          <span>
            (From: {senderNumber})
          </span>
        </div>
        <div className='text-[10px] text-red-400'>
          {selectedDEs.map(item => item.name.trim()).join(', ')}
        </div>
        <div className='text-[10px] text-gray-600'>Creator: {data?.createdBy}</div>
        <div className='flex items-center text-[10px] text-gray-600 italic'>
          <span>
            {data.status === EMessageStatus.Sent ? 'Sent ' : 'Updated '}{formatTime(data?.updatedAt)} - {new Date(data?.updatedAt).toLocaleString()}
          </span>
          {
            data.status === EMessageStatus.Sent && (
              <span className='ml-2 text-xs font-medium mr-2 px-2.5 py-0.5 rounded uppercase border bg-gray-100 text-gray-800 border-gray-400'>
                {new Date(data.updatedAt || 0).toLocaleTimeString('en-US', { timeZone: "America/New_York", weekday: 'long' })}
              </span>
            )
          }
        </div>
      </div>
      {
        data.status === EMessageStatus.Sent ? (
          <div className='flex flex-col flex-1'>
            <div className='flex-grow grid grid-cols-3 gap-2'>
              <StatisticLabel
                text='Sent Rate'
                currentPercentage={(data.totalSent / data.totalRow) * 100}
                ranges={[80, 90, 95, 100]}
              />
              <StatisticLabel
                text='Click Rate'
                currentPercentage={(rowHasClicked / sentItems.length) * 100}
                ranges={[1, 3, 5, 10, 15]}
              />
              <StatisticLabel
                text='Opt-out Rate'
                currentPercentage={(totalOptout.length / data.totalSent) * 100}
                ranges={[3, 5, 10]}
                higherIsBad
              />
            </div>
            <div className='flex flex-row justify-end'>
              <button
                disabled={downloadLoading || data?.clickRate === 0}
                onClick={() => onClickDownload()}
                className='capitalize space-x-2 focus:outline-none flex flex-row items-center text-white bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 disabled:bg-gray-400'>
                <FontAwesomeIcon icon={faDownload} />
                <span>Report</span>
                {
                  downloadLoading && (
                    <svg aria-hidden="true" className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                  )
                }

              </button>
              <CSVLink
                // @ts-ignore
                ref={theDownloadBtn}
                className='hidden'
                filename={`${data.name}_${todayLink}.csv`}
                data={dataToDownload}>Download me</CSVLink>
            </div>
          </div>
        ) : [EMessageStatus.Ready, EMessageStatus.New, EMessageStatus.Draft].includes(data.status) ? (
          <div className='cursor-pointer' onClick={() => onDeleteCampaign()}>
            <FontAwesomeIcon icon={faTrash} color='red' size='lg' />
          </div>
        ) : <></>
      }
    </div>
  )
};

export default memo(CampaignCard);
