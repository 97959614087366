import ISendAction, { ESendAction } from '../models/send-action';
import ITrackingURL from '../models/tracking-url';

export const calculateTotalClick = (row: ISendAction, trackingUrls: any[]): number => {
  // Temporary add Status ready -> remove later
  if (row.status !== ESendAction.Sent && row.status !== ESendAction.Ready) {
    return 0;
  }

  let total = 0;

  trackingUrls.forEach(item => {
    if ((row?.trackingIds || []).includes(item.id)) {
      total = total + item.clickCount
    };
  });

  return total;
}

export const calculateTotalClickByCampaign = (ids: string[], trackingUrls: ITrackingURL[]): number => {
  let total = 0;

  trackingUrls.forEach(item => {
    if (ids.includes(item.id)) {
      total = total + item.clickCount
    };
  });

  return total;
}

export const formatMessage = (input: string, domain = 'tinyurl.com'): string => {
  // eslint-disable-next-line
  const regex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
  const allResults = input.match(regex);
  let output = input;
  allResults?.forEach((item, index) => {
    output = output.replace(item, `https://${domain?.replace('/', '').replace('https://', '')}/uuidxx-${index}`);
  });

  return output;
};


export function getCurrentWeek() {
  // Get the current date
  const currentDate = new Date();
  const aDay = 24 * 60 * 60 * 1000;

  // Get the day of the week (0-6, where 0 is Sunday and 6 is Saturday)
  const currentDayOfWeek = currentDate.getDay();

  // Calculate the start date of the week (assuming Sunday as the start)
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - currentDayOfWeek);

  // Calculate the end date of the week
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  // Format the dates as strings (you can adjust the format as needed)
  const startDateFinal = new Date(startDate.getTime());
  startDateFinal.setHours(0);
  startDateFinal.setMinutes(0);
  startDateFinal.setMilliseconds(0);
  startDateFinal.setSeconds(0);

  const endDateFinal = new Date(endDate.getTime() + aDay);
  endDateFinal.setHours(23);
  endDateFinal.setMinutes(59);
  endDateFinal.setMilliseconds(999);
  endDateFinal.setSeconds(59);

  return [startDateFinal, endDateFinal];
}
